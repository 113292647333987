/* @font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
   url("./fonts/Inter-Medium.ttf") format("truetype");
  font-weight: bold;
 } */

body {
  margin: 0;
  padding: 0;
  font-family: "Inter-Medium" !important;
}

code {
  font-family: "Inter-Medium" !important;
}

.makeStyles-root-4 {
  align-items: center;
}

.makeStyles-main-3 {
  padding-top: 65px;
}

.MuiLink-underlineAlways {
  text-decoration: auto;
}

.container {
  width: 1200px;
  margin: 0px auto;
}

.p-0 {
  padding: 0px !important;
}
